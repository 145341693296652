<template>
  <div class="result">
      <h1>您没有权限访问</h1>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
  }
}
</script>


<style scoped>
.result{
  padding:10px;
  text-align: center;
}

img{
  width:120px;
  margin: 50px 0 30px;
}

p{
  color: #333;
}
</style>
